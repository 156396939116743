<template>
  <div class="exception-page">
    <div class="content">
      <div class="action">
        <a-button type="primary" @click="backHome">返回首页</a-button>
      </div>
    </div>
  </div>
</template>

<script>
// import CommonLayout from "@/layouts/CommonLayout";
// import { login, getRoutesConfig } from "@/services/user";
// import { setAuthorization } from "@/utils/request";
// import { loadRoutes } from "@/utils/routerUtil";
// import { mapMutations } from "vuex";

export default {
  name: "Login",
  components: { 
    // CommonLayout 
  },
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this),
    };
  },
  computed: {
    systemName() {
      return ''
      // return this.$store.state.setting.systemName;
    },
  },
  mounted(){

  },
  methods: {
    backHome() {
      let token = window.localStorage.getItem("token");
      if(token){
        this.$router.push('/');
      }else{
        this.$router.push('/login');
      }
    }
  },
};
</script>

<style lang="less" scoped>
  .exception-page{
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/common/404.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
    .content{
      margin-top: 20%;
    }
  }
</style>
